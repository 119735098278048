import React from "react";
import { View, TouchableOpacity, Image } from "react-native";
import { getAuth, signOut } from "firebase/auth";
import { Ionicons } from "@expo/vector-icons";
import {
  Layout,
  Text,
  TopNav,
  Section,
  SectionContent,
  useTheme,
  themeColor,
} from "react-native-rapi-ui";

export default function ({ navigation }) {
  const { isDarkmode, setTheme } = useTheme();
  const auth = getAuth();

  return (
    <Layout>
      <TopNav
        middleContent="Tasteful Trends"
        rightContent={
          <TouchableOpacity
            onPress={() => signOut(auth)}
            style={styles.logoutContainer}
          >
            {/* <Text style={styles.logoutText}>Logout</Text> */}
            <Ionicons
              name="exit"
              size={20}
              color={isDarkmode ? themeColor.white100 : themeColor.black}
              style={styles.logoutIcon}
            />
          </TouchableOpacity>
        }
        leftContent={
          <TouchableOpacity
            onPress={() => {
              isDarkmode ? setTheme("light") : setTheme("dark");
            }}
            style={styles.themeToggleContainer}
          >
            <Text style={styles.themeToggleText}>
              {isDarkmode ? "☀️ light" : "🌑 dark"}
            </Text>
          </TouchableOpacity>
        }
      />
      <View style={styles.mainContainer}>
        <Text size="h1" fontWeight="bold" style={styles.title}>
          Local Rewards Scheme
        </Text>
        <Image style={styles.logo} source={require("../../assets/logo.png")} />
        {/* <Section style={styles.section}> */}
          <View style={styles.sectionContent}>
            <Text size="lg" style={styles.instructionText}>
              Use this code in store to claim your points:
            </Text>
            <Text size="h1" status="success" style={styles.codeText}>
              {auth.currentUser.uid.substring(0, 8).toLowerCase()}
            </Text>
          </View>
        {/* </Section> */}
      </View>
    </Layout>
  );
}

const styles = {
  mainContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "space-between",
    marginHorizontal: 20,
  },
  logoutContainer: {
    flexDirection: "row",
    alignItems: "center",
    width: "25%",
    maxWidth: 100,
    marginRight: 12,
  },
  logoutText: {
    paddingRight: 6,
  },
  logoutIcon: {
    paddingLeft: 6,
  },
  themeToggleContainer: {
    flexDirection: "row",
    alignItems: "center",
    width: "25%",
    maxWidth: 100,
  },
  themeToggleText: {
    paddingLeft: 6,
    fontWeight: "bold",
  },
  title: {
    textAlign: "center",
    marginTop: 12,
  },
  logo: {
    width: 290,
    height: "50%",
    minHeight: 200,
    maxHeight: 340,
    resizeMode: "contain",
    marginBottom: 12,
  },
  section: {
    width: "100%",
    alignItems: "center",
  },
  sectionContent: {
    borderColor: "#FBDD6F",
    borderWidth: 6,
    borderRadius: 12,
    alignItems: "center",
    padding: 33, 
  },
  instructionText: {
    textAlign: "center",
    paddingBottom: 12,
  },
  codeText: {
    letterSpacing: 5,
    textAlign: "center",
  },
};
