import React from "react";
import {
  View,
  Image,
  ScrollView,
  SafeAreaView,
  TouchableOpacity,
  StyleSheet,
} from "react-native";
import {
  Text,
  Layout,
  TopNav,
  useTheme,
  themeColor,
  Section,
  SectionContent,
} from "react-native-rapi-ui";
import { Ionicons } from "@expo/vector-icons";
import PointGrid from "../components/PointGrid";
import imageMappings from "../imageMappings";
const LocationPoints = ({ navigation, route }) => {
  const { location, points } = route.params;
  const { isDarkmode } = useTheme();

  // Fake data for rewards
  const rewardsData = [
    {
      id: 1,
      stage: 1,
      points: 10,
      title: "1 Free Coffee",
      location: "Mill and Brae",
    },
    {
      id: 7,
      stage: 1,
      points: 10,
      title: "1 Free Cake",
      location: "Mill and Brae",
    },
    {
      id: 3,
      stage: 2,
      points: 25,
      title: "Free Dessert",
      location: "Mill and Brae",
    },
    {
      id: 4,
      stage: 2,
      points: 25,
      title: "10% Off Dinner",
      location: "Mill and Brae",
    },
    {
      id: 5,
      stage: 3,
      points: 50,
      title: "Free Appetizer",
      location: "Mill and Brae",
    },
    {
      id: 6,
      stage: 3,
      points: 50,
      title: "20% Off Any Purchase",
      location: "Mill and Brae",
    },
  ];

  return (
    <Layout style={{}}>
      <TopNav
        leftContent={
          <Ionicons
            name="chevron-back"
            size={20}
            color={isDarkmode ? themeColor.white100 : themeColor.black}
          />
        }
        leftAction={() => navigation.goBack()}
      />
      <SafeAreaView style={{backgroundColor: isDarkmode ? themeColor.black300 : themeColor.white100}}>

        <ScrollView contentContainerStyle={{}}>
          <View style={{ flex: 1, padding: 20 }}>
            <Image
              source={imageMappings[location.locationid]}
              style={{ width: "100%", height: 200 }}
            />
            <Text
              style={{
                fontSize: 24,
                fontWeight: "bold",
                marginTop: 20,
                marginBottom: 10,
                color: isDarkmode ? themeColor.white100 : themeColor.black,
              }}
            >
              {location.name}
            </Text>
            <Section>
              <SectionContent>
                <Text
                  style={{
                    fontSize: 16,
                    marginBottom: 20,
                    color: isDarkmode ? themeColor.white100 : themeColor.black,
                  }}
                >
                  Description: {location.description}
                </Text>
                <Text
                  style={{
                    fontSize: 16,
                    color: isDarkmode ? themeColor.white100 : themeColor.black,
                  }}
                >
                  Address: {location.address}
                </Text>
              </SectionContent>
            </Section>
            <Text
              style={{
                fontSize: 18,
                fontWeight: "bold",
                marginTop: 20,
                marginBottom: 6,
                color: isDarkmode ? themeColor.white100 : themeColor.black,
              }}
            >
              Points collected: {points.length}
            </Text>
            <Section>
              <SectionContent>
                <PointGrid points={points} />
              </SectionContent>
            </Section>
            <Text
              style={{
                fontSize: 18,
                fontWeight: "bold",
                marginTop: 20,
                marginBottom: 6,
                color: isDarkmode ? themeColor.white100 : themeColor.black,
              }}
            >
              Rewards on offer
            </Text>

            {[1, 2, 3].map((stage) => (
              <Section
                backgroundColor={
                  stage === 1 ? "#FFEB3B" : stage === 2 ? "#FFC107" : "#FF9800"
                }
                key={stage}
                style={[styles.stageContainer]}
              >
                <SectionContent>
                  <Text
                    size="h3"
                    style={{ color: stage === 3 ? "#FFFFFF" : "#212121" }}
                  >
                    Stage {stage} - {stage === 1 ? 10 : stage === 2 ? 25 : 50}{" "}
                    points
                  </Text>
                  {/* Iterate through rewards data for current stage */}
                  <View style={styles.rewardsContainer}>
                    {rewardsData
                      .filter((reward) => reward.stage === stage) // Filter rewards by stage
                      .map((reward, index) => (
                        <Section style={{padding: 0, margin: 0, marginBottom: 12,}}><SectionContent>
                        <TouchableOpacity
                          key={index}
                          onPress={() => navigation.navigate("SecondScreen")}
                          style={{ width: `100%`, flexDirection: 'row' }}
                        >
                          <View style={styles.rewardItem}>
                          {points.length >= (reward.points - 6) && (
    <Image style={styles.image} source={require("../../assets/logo.png")} />
  )}
  {/* Render red circle if points are not enough */}
  {points.length < (reward.points - 6) && (
    <View style={[styles.imagered, { backgroundColor: 'red', borderRadius: 50, marginRight: 12,}]} />
  )}
                            <View style={styles.rewardTextContainer}>
                              <Text
                                numberOfLines={1}
                                style={styles.rewardTitle}
                                size="h3"
                              >
                                {reward.title}
                              </Text>
                              {/* <Text style={styles.rewardLocation}>{reward.location}</Text> */}
                              {points.length >= (reward.points - 6) && (
                                <Text
                                numberOfLines={1}
                                style={styles.rewardGranted}
                                color='green'
                              >
                                CLAIM REWARD
                              </Text>
)}
                            </View>
                          </View>
                        </TouchableOpacity>
                      </SectionContent></Section>
                      ))}
                  </View>
                </SectionContent>
              </Section>
            ))}
          </View>
          <View style={{ height: 100 }}></View>
        </ScrollView>
      </SafeAreaView>
    </Layout>
  );
};

const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 1,
  },
  stageContainer: {
    // padding: 20,
    marginBottom: 20,
  },
  rewardsContainer: {
    flexDirection: "column",
    // flexWrap: "wrap",
    // justifyContent: "space-between",
    marginTop: 10,
    width: "100%",
  },
  rewardItem: {
    // width: '38%',
    // marginBottom: 10,
    // backgroundColor: isDarkmode ? themeColor.white100 : themeColor.black300,
    // padding: 12,
    // borderRadius: 12,
    flexDirection: "row",
    alignItems: "center",
  },
  image: {
    width: 100,
    height: 100,
    resizeMode: "contain",
    marginRight: 12,
  },
  imagered: {
    width: 50,
    height: 50,
    resizeMode: "contain",
    marginRight: 12,
  },
  rewardTextContainer: {
    flexShrink: 1, // Allow text to wrap
    textAlign: "center",
  },
  rewardTitle: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 4,
    color: "white",
  },
  rewardGranted: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 4,
    color: "green",
  },
  rewardLocation: {
    fontSize: 12,
    color: "#757575",
  },
});
export default LocationPoints;
