import React from "react";
import { View, ScrollView, Image, TouchableOpacity, StyleSheet } from "react-native";
import { Layout, Text, TopNav, themeColor } from "react-native-rapi-ui";

// Fake data for rewards
const rewardsData = [
  { id: 1, stage: 1, points: 10, title: "1 Free Coffee", location: "Mill and Brae" },
  { id: 7, stage: 1, points: 10, title: "1 Free Coffee", location: "Mill and Brae" },
  { id: 8, stage: 1, points: 10, title: "1 Free Cake", location: "Bianco Nero" },
  { id: 2, stage: 1, points: 10, title: "50% Off Lunch", location: "Café Luna" },
  { id: 3, stage: 2, points: 25, title: "Free Dessert", location: "La Petite Boulangerie" },
  { id: 4, stage: 2, points: 25, title: "10% Off Dinner", location: "The Olive Tree" },
  { id: 5, stage: 3, points: 50, title: "Free Appetizer", location: "Bistro 52" },
  { id: 6, stage: 3, points: 50, title: "20% Off Any Purchase", location: "The Riverside" },
];

export default function RewardsScreen({ navigation }) {
  return (
    <Layout>
      <TopNav middleContent="Your Rewards" />
      <ScrollView contentContainerStyle={styles.scrollView}>
        {/* Iterate through each stage */}
        {[1, 2, 3].map((stage) => (
          <View key={stage} style={[styles.stageContainer, { backgroundColor: stage === 1 ? "#FFEB3B" : stage === 2 ? "#FFC107" : "#FF9800" }]}>
            <Text size="h1" style={{ color: stage === 3 ? "#FFFFFF" : "#212121" }}>
              Stage {stage} - {stage === 1 ? 10 : stage === 2 ? 25 : 50} points
            </Text>
            {/* Iterate through rewards data for current stage */}
            <View style={styles.rewardsContainer}>
              {rewardsData
                .filter((reward) => reward.stage === stage) // Filter rewards by stage
                .map((reward, index) => (
                  <TouchableOpacity key={index} onPress={() => navigation.navigate("SecondScreen")} style={{width:'48%'}}>
                    <View style={styles.rewardItem}>
                      <Image style={styles.image} source={require("../../assets/logo.png")} />
                      <View style={styles.rewardTextContainer}>
                        <Text numberOfLines={2} style={styles.rewardTitle}>{reward.title}</Text>
                        <Text style={styles.rewardLocation}>{reward.location}</Text>
                      </View>
                    </View>
                  </TouchableOpacity>
                ))}
            </View>
          </View>
        ))}
      </ScrollView>
    </Layout>
  );
}

const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 1,
  },
  stageContainer: {
    padding: 20,
    marginBottom: 20,
  },
  rewardsContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginTop: 10,
  width: "100%", // Two items per row, leaving space between

  },
  rewardItem: {
    // width: '38%',
    marginBottom: 10,
    backgroundColor: themeColor.black300,
    padding: 12,
    borderRadius: 12,
    flexDirection: "column",
    alignItems: "center",

  },
  image: {
    width: 100,
    height: 100,
    resizeMode: "contain",
  },
  rewardTextContainer: {
    // marginLeft: 10,
    flexShrink: 1, // Allow text to wrap
    textAlign: 'center'
  },
  rewardTitle: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 4,
  },
  rewardLocation: {
    fontSize: 12,
    color: "#757575",
  },
});
