import React, { useState, useEffect } from "react";
import LocationList from "./LocationList"; // Import LocationList component
import * as db from "../data/db";
import { View, StyleSheet,  } from "react-native";
import { Text } from "react-native-rapi-ui";

const Points = ({ userId }) => {
  const [locationData, setLocationData] = useState([]); // Stores grouped location data
  const [isLoading, setIsLoading] = useState(true); // Loading state

  // Fetch user points and process them
  useEffect(() => {
    const fetchData = async () => {
      // setIsLoading(true);
      try {
        // console.log(userId);
        const userPoints = await db.getUserPointsFromAPI(userId); // Replace with your actual database function
        const processedData = await db.processPoints(userPoints);
        await setLocationData(processedData);
        // setIsLoading(false);
      // }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
      }
    };

    fetchData();
  }, [userId]);

  return (
<View style={styles.container}>
  <LocationList locations={locationData} />
</View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 16,
  },
});

export default Points;
