import React from 'react'; // Import React if not already imported
import { View, Text } from "react-native";
import { useTheme, themeColor } from "react-native-rapi-ui";

const PointGrid = ({ points }) => {
  const { isDarkmode } = useTheme();

  return (
    <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-evenly' }}>
      {points.map((point, index) => (
        <View
          key={index}
          style={{
            width: '20%',
            marginTop: 6,
            marginBottom: 6,
            marginHorizontal: 3,
            borderColor: '#FFF8DC',
            borderWidth: 1,
            paddingTop: 9,
            paddingBottom: 3,
            borderRadius: 100,
          }}
        >
          {point.datecollected && ( // Check if datecollected exists
          <View style={{flexDirection: 'column', flexWrap: 'nowrap', justifyContent: 'center', alignItems: 'center'}}>
                          <Text
                          style={{
                            position: 'relative',
                            // top: '50%',
                            // left: '50%',
                            // transform: [{ translateX: -50 }, ],
                            color: isDarkmode ? themeColor.white100 : themeColor.black,
                          }}
                        >
                          +1
                        </Text>
            <Text
              style={{
                color: isDarkmode ? themeColor.white100 : themeColor.black,
                textAlign: 'center',
                // marginTop: 5,
              }}
            >
              {new Date(point.datecollected).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit' })} {/* Format date */}
            </Text>
        </View>
          )}
        </View>
      ))}
    </View>
  );
};

export default PointGrid;
