import React, { useState, useEffect } from "react";
import { View, FlatList } from "react-native";
import {
  Layout,
  Text,
  TopNav,
  themeColor,
  useTheme,
} from "react-native-rapi-ui";
import Points from "../components/Points";
import { Ionicons } from "@expo/vector-icons";
import * as db from "../data/db";
export default function ({ navigation }) {
  const { isDarkmode } = useTheme();
  const [uid, setUid] = useState(db.getUserID());
  return (
    <Layout>
      <TopNav
        middleContent="Tasteful Trends"
        rightContent={
          <Ionicons
            name="refresh"
            size={20}
            color={isDarkmode ? themeColor.white100 : themeColor.black}
          />
        }
        rightAction={() => console.log("setting icon pressed")}
      />
      <View
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "flex-start",
          marginTop: 20,
        }}
      >
        <Points userId={uid} />
      </View>
    </Layout>
  );
}
