import React, { useContext } from "react";
import { AuthContext } from "../provider/AuthProvider";

const applicationURL = 'https://clients.joshuamedia.co.uk/api'
// const applicationURL = 'http://locahost:9000/api'



const data = [
  {
    uid: "p2rbxqsd",
    username: "John Doe",
    locations: [
      {
        location_id: "loc1",
        name: "Mill & Brae",
        description: "Description of Location 1",
        address: "123 Main St",
      },
      {
        location_id: "loc2",
        name: "Bianco Nero",
        description: "Description of Location 2",
        address: "456 Elm St",
      },
    ],
    points: [
      {
        point_id: "point1",
        uid: "p2rbxqsd",
        location_id: "loc1",
        date_collected: new Date("2023-01-01T12:00:00.000Z"),
      },
      {
        point_id: "point3",
        uid: "p2rbxqsd",
        location_id: "loc1",
        date_collected: new Date("2023-01-02T12:00:00.000Z"),
      },
      {
        point_id: "point2",
        uid: "p2rbxqsd",
        location_id: "loc2",
        date_collected: new Date("2023-01-15T14:30:00.000Z"),
      },
    ],
  },
  {
    uid: "user2",
    username: "Jane Smith",
    locations: [
      {
        location_id: "loc1",
        name: "Location 1",
        description: "Description of Location 1",
        address: "123 Main St",
      },
    ],
    points: [
      {
        point_id: "point3",
        uid: "user2",
        location_id: "loc1",
        date_collected: new Date("2023-02-01T10:00:00.000Z"),
      },
    ],
  },
];




const getUserID = () => {
  const auth = useContext(AuthContext);
  const userID = auth.uid.substring(0, 8).toLowerCase();
  return userID;
};

const getLocationsFromDB = async () => {
    const response = await fetch('https://clients.joshuamedia.co.uk/serve/read_locations.php'); // Replace with your endpoint URL
    // console.log(response)
    if (!response.ok) {
      throw new Error('Failed to fetch locations');
    }
    const data = await response.json();
    // console.log(data)
    return data
}
const getLocationDataFromDB = async (location_id) => {
    const response = await fetch(`https://clients.joshuamedia.co.uk/serve/read_location_data.php?location_id=${location_id}`); // Replace with your endpoint URL
    // console.log(response)
    if (!response.ok) {
      throw new Error('Failed to fetch location');
    }
    const data = await response.json();
    // console.log(data)
    return data
}



const getUserPointsFromDB = async (userID) => {
    const response = await fetch(`https://clients.joshuamedia.co.uk/serve/get_points.php?uid=${userID}`)
    if (!response.ok) {
      throw new Error('Failed to fetch locations');
    }
    const data = await response.json();
    // await console.log(data)
    return data
}



const getUserPointsFromAPI = async (userID) => {
  const response = await fetch(`https://clients.joshuamedia.co.uk/api/points/user/${userID}`)
  // console.log(response)
  if (!response.ok) {
    throw new Error('Failed to fetch locations');
  }
  const data = await response.json();
  // await console.log(data)
  return data
}


const processPoints = async (pointsData) => {
  const pointsByLocation = pointsData.reduce((acc, point) => {
    const { locationid } = point;
    (acc[locationid] || (acc[locationid] = [])).push(point);
    return acc;
  }, {});
  const locationDataPromises = Object.keys(pointsByLocation).map(async (locationId) => {
    const locationResponse = await fetch(`https://clients.joshuamedia.co.uk/api/locations/${locationId}`);
    const locationData = await locationResponse.json();
    return { locationId, locationData };
  });

  const locationData = await Promise.all(locationDataPromises);

  // Combine point data with location data
  const result = Object.entries(pointsByLocation).map(([locationId, points]) => ({
    locationId,
    points,
    locationData: locationData.find(ld => ld.locationId === locationId).locationData
  }));

  return result;
}



// const newdata = await getUserPointsFromAPI('p2rbxqsd')
// console.log('HELLOOOOOOO')
// console.log(newdata)
// const pointsdata = await processPoints(newdata)
// console.log('HELLOOOOOOO 2')
// console.log(pointsdata)


getLocationsFromDB()
getUserPointsFromDB()
const getUser = (userID) => {
  const user = data.find((user) => user.uid === userID);
  if (!user) {
    // Handle the case where no user is found
    console.error(`No user found with ID ${userID}`);
    return null;
  }
  return user;
};

const getUserPoints = (userID) => {
  const user = getUser(userID);
  const points = user.points;
  if (!points) return null;
  return { points };
};
const getLocations = (userID) => {
  const user = getUser(userID);
  const locations = user.locations;
  if (!locations) return null;
  return { userID, locations: locations };
};

const getLocationData = (locationID) => {
  const userID = getUserID();
  const user = getUser(userID);
  const locations = user.locations;
  //   if (!locations) return null;
  const locationData = locations.filter(
    (location) => location.location_id === locationID
  );

  return { locationData };
};
// if (!user) return null;

const getUserPointsByLocation = (userID) => {
  const user = getUser(userID);

  let pointsByLocation = [];
  let locations = [];

  user.locations.forEach((location) => {
    const locationPoints = user.points.filter(
      (point) => point.location_id === location_id
    );
    pointsByLocation.push({ ...location, points: locationPoints.length });
  });
  return pointsByLocation;
};

const getUserLocationPoints = (userID) => {
  const userPoints = getUserPoints(userID);
  let locations = {};

  // Filter through and create an array of locations
  userPoints.points.forEach((point) => {
    if (!locations[point.location_id]) {
      locations[point.location_id] = {
        location: point.location_id,
        points: [point],
      };
    } else {
      locations[point.location_id].points.push(point);
    }
  });

  return Object.values(locations);
};

export {
  getUserID,
  getUserPoints,
  getLocations,
  getUserPointsByLocation,
  getUserLocationPoints,
  getLocationData,
  getUserPointsFromDB,
  getLocationsFromDB,
  getLocationDataFromDB,
  getUserPointsFromAPI,
  processPoints,
};
