import React from "react";
import {
  Text,
  Section,
  SectionContent,
  SectionImage,
  Avatar
} from "react-native-rapi-ui";
import { View, ScrollView, StyleSheet, Dimensions, SafeAreaView, TouchableOpacity, Image } from "react-native";
import { useNavigation } from "@react-navigation/native";
import PointGrid from './PointGrid'; // Import PointGrid component
import imageMappings from '../imageMappings'; // Adjust the import path as necessary

const LocationList = ({ locations }) => {
  const navigation = useNavigation();

  console.log(locations); // For debugging

  return (
    <SafeAreaView style={styles.container}>
        <Text size="h1" fontWeight="bold" style={{marginBottom: 12, marginTop: 20,}}>
    Your Points
  </Text>
      <ScrollView contentContainerStyle={styles.scrollViewContent}>
        {locations.map((location, index) => (
          <Section
            style={styles.section}
            key={index}
          >
            <TouchableOpacity
              onPress={() => {
                navigation.navigate("LocationPoints", { // Navigate to LocationPoints.js
                  location: location.locationData,
                  points: location.points,
                });
              }}
            >
              {/* <SectionImage source={imageMappings[location.locationid]} style={styles.image} /> */}
              <SectionContent style={{flexDirection: 'row', flexWrap: 'nowrap'}}>
                <View>
                <Avatar source={imageMappings[location.locationData.locationid]} style={{marginRight: 12,}}/>
                </View>
                <View style={{width: `80%`, minWidth: `70%`, maxWidth: `75%` }}>
                  <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                    <Text size="h3" style={styles.locationName}>{location.locationData.name}</Text>

                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                      <Text size="sm">total:  </Text>
                      <Text size="h3" style={styles.points}>{location.points.length}</Text>
                    </View>
                  </View>
                  <Text>{location.locationData.description}</Text>
                  {/* <br /> */}
                </View>
              </SectionContent>
            </TouchableOpacity>
          </Section>
        ))}

        {/* Added dummy content to ensure scroll view height */}
        {!locations.length && ( // Check if there are no locations
          <View style={styles.noDataContainer}>
            <Text>No locations found.</Text>
          </View>
        )}
      </ScrollView>
    </SafeAreaView>
        
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    minHeight: 600, // Adjust height as needed
    height: `100%`, // Adjust height as needed
  },
  flexFiller: {
    minHeight: 120, // Set a specific height for the filler
    maxHeight: 200, // Set a specific height for the filler
    height: 200, // Set a specific height for the filler
  },
  scrollViewContent: {
    flexGrow: 1,
    paddingHorizontal: 6,
    paddingBottom: 20,
    // minHeight: 600, // Adjust height as needed
    height: 600, // Adjust height as needed
  },
  section: {
    // width: "100%",
    borderColor: "#FBDD6F",
    borderWidth: 6,
    marginVertical: 12,
    maxHeight: 200,
  },
  // image: {
    // width: "100%",
    // height: 200, // Adjust height as needed
  // },
  locationName: {
    fontSize: 18,
    fontWeight: "bold",
  },
  pointGridContainer: {
    marginTop: 10, // Add margin for spacing
  },
  noDataContainer: {
    flex: 1, // Force scroll view height when no data
    justifyContent: "center",
    alignItems: "center",
  },
});

export default LocationList;
